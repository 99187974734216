<template>
  <section
    class="template-dashboard-view edit-new-template-view"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
  >
    <div
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      v-loading.fullscreen.lock="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :fullscreen="true"
    >
      <div class="templates-title-block">
        <el-row style="margin: 0px 0 30px 0">
          <el-col :span="24">
            <el-row>
              <el-col :span="2" class="centered-col">
                <el-button size="mini" @click="handleBack">
                  <i class="el-icon-back"></i> back</el-button
                >
              </el-col>
              <el-col :span="4">
                <h2
                  class="title fw-normal text-dark mb-0 ls-05"
                  style="margin-top: 5px"
                >
                  Email Templates
                </h2>
              </el-col>
              <el-col :span="2" style="float: right">
                <el-button
                  type="primary"
                  :disabled="
                    !form.name ||
                    !form.subject ||
                    !form.content ||
                    !form.entity_id
                  "
                  @click="createNewTemplate"
                >
                  {{ isEdit ? "Update" : "Save" }}
                </el-button>
              </el-col>
            </el-row>
            <el-card shadow="never" style="margin-top: 5px; position: relative">
              <el-row class="Email-Templates">
                <el-col :span="14.5" style="padding: 3px">
                  <el-scrollbar>
                    <div style="margin-top: 5px; margin-right: 20px">
                      <h5 style="font-weight: 400">Template Name <span style="color: red; font-weight: bold">*</span></h5>
                      <el-input
                        v-model="form.name"
                        style="width: 700px"
                        placeholder="Enter Template Name"
                      ></el-input>
                    </div>
                    <div style="margin-top: 15px">
                      <h5 style="font-weight: 400">Select Entity <span style="color: red; font-weight: bold">*</span></h5>
                      <el-select
                        v-model="form.entity_id"
                        placeholder="Select Entities"
                        filterable
                        collapse-tags
                        style="width: 700px"
                        @change="handleEntityChange()"
                      >
                        <el-option
                          v-for="(item, index) of allEntities"
                          v-bind:key="index"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </div>
                    <div v-if="form.entity_id" style="margin-top: 15px">
                      <h5 style="font-weight: 400">Description</h5>
                      <el-input
                        v-model="form.description"
                        style="width: 700px"
                        placeholder="Enter Description"
                        type="textarea"
                        maxlength="500"
                        show-word-limit
                      ></el-input>
                    </div>
                    <div v-if="form.entity_id" style="margin-top: 15px">
                      <h5 style="font-weight: 400">Email Subject <span style="color: red; font-weight: bold">*</span></h5>
                      <el-input
                        v-model="form.subject"
                        type="textarea"
                        style="width: 345px"
                        maxlength="998"
                        rows="2"
                        show-word-limit
                        placeholder="Enter Subject"
                      ></el-input>
                      <el-select
                        v-model="copyField"
                        placeholder="Select Field to copy"
                        filterable
                        collapse-tags
                        :disabled="!form.entity_id"
                        @change="copyURL"
                        style="width: 350px"
                        class="Select-in-Email-Templates"
                      >
                        <el-option
                          v-for="(item, index) of allMentionableFields"
                          v-bind:key="index"
                          :label="item.label"
                          :value="item.label"
                        >
                          {{ item.label }} ({{ item.input_type }})
                          <a
                            v-if="item.isSubjectSupported"
                            @click.stop="pushToSubject(item.label)"
                          >
                            <i class="el-icon-right"></i>
                          </a>
                        </el-option>
                      </el-select>
                    </div>
                    <div style="margin-top: 15px" v-if="form.entity_id">
                      <div>
                        <span style="font-weight: 400">Content</span>
                        <el-button
                          class="ml-10"
                          style="border: none; color: #409eff"
                          size="mini"
                          @click="openTableConfig"
                        >
                          Configure Relational data tables
                        </el-button>
                      </div>
                      <div
                        v-loading="ckeditorLoading"
                        :element-loading-text="loadingText"
                        style="margin-top: 10px"
                      >
                        <ckeditor
                          v-if="isEnable"
                          v-model="form.content"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                    </div>
                  </el-scrollbar>
                </el-col>

                <el-col class="fixed-col">
                  <el-button
                    class="outlined-icon-button try-using-ai"
                    @click="openChatbot"
                    ><div style="display: flex">
                      <icons :iconName="'TryUsingAI'"></icons>
                      <div style="padding-top: 6px; margin-left: 6px">
                        Try Using AI
                      </div>
                    </div>
                  </el-button>
                  <div v-if="showChatbot" style="width: 100%">
                    <ChatGpt
                      @close-chatbot="CloseChatBot"
                      @copy-content="updateEditorContent"
                      :entity_fields="allMentionableFields"
                      :global_fields="allCompanyDataFields"
                      :entity="entityIdObject[form.entity_id]"
                      type="email_template"
                    />
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <dialog-component
      append-to-body
      :title="'Configure Table'"
      :visible="configureTableDialog"
      @before-close="ConfigureTableDialog"
      :containerWidth="'50%'"
    >
      <div style="margin: 15px">
        <emailRelationalDataTable
          :allEntitiesData="allEntitiesData"
          :data="relationalTableData"
          :fields="allEntityFields"
          :allCompanyDataFields="allCompanyDataFields"
          v-on:save-all-tables="handleTableConfigClose"
        >
        </emailRelationalDataTable>
      </div>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import EntityHelper from "@/mixins/EntitiesHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import axios from "@/config/axios";
import ChatGpt from "../ChatGpt-EmailTemplates.vue";

export default {
  name: "formTemplates-New-Template",
  data() {
    return {
      showChatbot: false,
      loadingText: "",
      loading: false,
      form: {
        name: "",
        entity_id: "",
        description: "",
        subject: "",
        content: "",
      },
      allEntities: [],
      editorConfig: ckEditorConfig,
      allMentionableFields: [],
      mentionableSubjectFields: [],
      entityIdObject: {},
      allCustomFields: [
        {
          id: "current_date",
          label: "Time - Current Date",
        },
        {
          id: "current_time",
          label: "Time - Current Time",
        },
        {
          id: "current_date_time",
          label: "Time - Current Date Time",
        },
        {
          id: "redirect_link_entity_data_edit",
          label: "Redirect Link - Company User - Data Edit",
        },
        {
          id: "redirect_link_entity_data_view",
          label: "Redirect Link - Company User - Data View",
        },
        {
          id: "redirect_link_ap_entity_data_edit",
          label: "Redirect Link - Application User - Data Edit",
        },
        {
          id: "redirect_link_ap_entity_data_view",
          label: "Redirect Link - Application User - Data View",
        },
      ],
      currentTemplateData: {},
      isEdit: false,
      allCompanyFields: [],
      allCompanyDataFields: [],
      relationalDataForm: {
        entity_id: "",
        filters: "",
        selectedFields: [],
      },
      isEnable: false,
      allEntitiesData: [],
      selectedEntityFields: [],
      filterLoading: false,
      configureTableDialog: false,
      entityAllFilters: [],
      copyField: "",
      relationalTableData: [],
      ckeditorLoading: false,
      mentionedRelationalTableFields: [],
      allEntityFields: [],
    };
  },
  async mounted() {
    this.loading = true;
    this.editorConfig.height = 300;
    this.editorConfig.width = 700;
    if (this.$route.path.includes("edit")) {
      this.isEdit = true;
      await this.fetchCurrentTemplateData(this.$route.params.template_id);
    }
    await this.getAllNecessaryDetails();
    this.isEnable = true;
    this.loading = false;
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("entityEmailTemplate", [
      "getAllCompanyEmailTemplatesData",
      "getDeleteEmailTemplateStatus",
      "getEmailTemplateError",
      "getCreatedEmailTemplateStatus",
      "getCurrentEmailTemplateData",
      "getUpdatedEmailTemplateStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getUpdateFilterError",
      "getDeleteFilterErros",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getParentEntityRelatiponshipData",
    ]),
    checkFormDisabled() {
      if (
        !this.relationalDataForm.entity_id ||
        !(
          this.relationalDataForm.selectedFields &&
          this.relationalDataForm.selectedFields.length
        )
      ) {
        return true;
      }
      return false;
    },
    checkAlreadyExists() {
      if (this.form.content.includes("[[relationalEntityDataTable]]")) {
        return false;
      }
      return true;
    },
  },
  mixins: [EntityHelper, NavigationHelper],
  components: {
    ChatGpt,
    emailRelationalDataTable: () =>
      import("@/components/notifications/emailRelationalDataTableConfig.vue"),
  },
  methods: {
    updateEditorContent(content) {
      this.form.content = content;
    },
    CloseChatBot() {
      this.showChatbot = false;
    },
    handleEntityChange() {
      this.getEntityDetails();
      this.CloseChatBot();
    },
    async getAllNecessaryDetails() {
      this.getCompanyInfo();
      await this.fetchEntities();
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}" data-template-id="{template_key}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate: (item) => {
            if (item.input_type == "IMAGE" || item.inputType == "IMAGE") {
              return `<img alt="${item.label}" 
                            src="https://app-esigns-io.s3.amazonaws.com/ck_editor_assets/MicrosoftTeams-image_8-1716639572596.png?field=[[${item.label}]]" 
                            style="width: 80px;"
                            title="${item.label}" />`;
            } else {
              return `<span>&nbsp;</span><span>[[${item.label}]]</span><span>&nbsp;</span>`;
            }
          },
          minChars: 0,
          maxItems: 20,
          style: {
            maxHeight: "100px",
            overflowY: "auto",
          },
        },
        {
          feed: this.companyDataFeed,
          marker: "#",
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><span>(({id}))</span><span>&nbsp;</span>",
          minChars: 0,
        },
      ];
      if (this.isEdit) {
        this.getEntityDetails(true);
      }
    },
    async getCompanyInfo() {
      const fieldMappings = {
        about_company_text: "Company -  Description",
        email_domain: "Company - Email Domain",
        email_footer_text: "Company - Email Footer",
        legal_name: "Company - Legal Name",
        name: "Company -  Name",
        logo: "Company - Logo",
      };
      this.allCompanyFields = Object.entries(fieldMappings).map(
        ([field, label]) => {
          return {
            label,
            id: field,
          };
        }
      );
      this.allCompanyDataFields = [
        ...this.allCustomFields,
        ...this.allCompanyFields,
      ];
      const userFieldMappings = {
        first_name: "Login User - First Name",
        last_name: "Login User - Last Name",
        email: "Login User - Email",
        phone: "Login User - Phone",
        address: "Login User - Address",
        owner_first_name: "Owner - First Name",
        owner_last_name: "Owner - Last Name",
        owner_email: "Owner - Email",
        owner_phone: "Owner - Phone",
        owner_address: "Owner - Address",
      };
      let userFields = Object.entries(userFieldMappings).map(
        ([field, label]) => {
          return {
            label,
            value: field,
            id: field,
          };
        }
      );
      this.allUserFields = userFields;
      this.allCompanyDataFields = [...this.allCompanyDataFields, ...userFields];
    },
    async fetchEntities() {
      try {
        let response = await axios.get("/entities/fetchAllEntities");
        if (response?.data?.data) {
          this.allEntities = [...response.data.data];
          this.allEntities.map((e) => {
            this.entityIdObject[e._id] = e;
          });
        }
      } catch (err) {
        console.log("fetchEntities", err);
      }
    },
    async getEntityDetails(mountedCall = false) {
      this.loading = true;
      this.loadingText = "Fetching entity Fields";
      this.allMentionableFields = await this.fetchEntityDetails(
        this.form.entity_id,
        true
      );
      this.allEntityFields = JSON.parse(
        JSON.stringify(this.allMentionableFields)
      );
      if (this.form.entity_id || this.isEdit) {
        await this.fetchRelationalEntities(this.form.entity_id);
      }
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "MULTI_LINE_TEXT",
      ];
      this.allMentionableFields = this.allMentionableFields.map((item) => ({
        label: item.label,
        template_key: item.template_key,
        id: item.template_key,
        input_type: item.inputType,
        isSubjectSupported: !notSupportedSubjectTypes.includes(item.inputType),
      }));
      // let supportedSubjectFields = ['SINGLE_LINE_TEXT',]
      if (!mountedCall) {
        this.resetForm(true);
      }
      this.loading = false;
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allMentionableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    openChatbot() {
      if (!this.form.entity_id) {
        this.$message.error({
          showClose: true,
          message: "Please Select The Entity",
          center: true,
        });
        return;
      }
      this.showChatbot = !this.showChatbot;
    },
    companyDataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allCompanyDataFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    resetForm(entityChange = false) {
      if (entityChange) {
        this.form.content = "";
        this.relationalDataForm = {
          entity_id: "",
          filters: "",
          selectedFields: [],
        };
      } else {
        this.form = {
          name: "",
          description: "",
          subject: "",
          content: "",
          entity_id: "",
        };
      }
    },
    handleTableConfigClose(data) {
      this.ckeditorLoading = true;
      this.loadingText = "Fetching Table data....";
      this.relationalTableData = [...data];
      this.mentionedRelationalTableFields = [];
      this.relationalTableData.map((e) => {
        this.mentionedRelationalTableFields.push(e.name);
        if (
           (e.type == "email_body" || e.typee == "dataTable") &&
          !this.form.content.includes(`[[DataTable-${e.name}]]`)
        ) {
          // let key = Math.floor(Math.random() * 100000);
          this.form.content =
            this.form.content + `<br> [[DataTable-${e.name}]] &nbsp;&nbsp;`;
        }
      });
      let regex = /\[\[DataTable-([^\]]+)\]\]/g;
      this.form.content = this.form.content.replace(
        regex,
        (match, fieldName) => {
          if (this.mentionedRelationalTableFields.includes(fieldName)) {
            return match;
          } else {
            return "";
          }
          }
      
      );
      this.configureTableDialog = false;
      this.ckeditorLoading = false;
    },
    async createNewTemplate() {
      if (
        !this.form.name.trim() ||
        !this.form.description.trim() ||
        !this.form.subject.trim()
      ) {
        return this.$message.warning(
          "Only spaces are not allowed.Please enter text"
        );
      }
      let labelAndFields = {};
      this.allMentionableFields.map((e) => {
        labelAndFields[e.label] = e;
      });
      let isSubjectValid = true;
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "MULTI_LINE_TEXT",
      ];
      let pattern = /\[\[([^[\]]*)\]\]/g;
      let mentionedSubjectLabels = [];
      let match;

      while ((match = pattern.exec(this.form.subject)) !== null) {
        mentionedSubjectLabels.push(match[1]);
      }
      mentionedSubjectLabels.map((e) => {
        const template = labelAndFields[e];
        if (
          template &&
          template.input_type &&
          notSupportedSubjectTypes.includes(template.input_type)
        ) {
          isSubjectValid = false;
        }
      });
      if (!isSubjectValid) {
        return this.$message.warning("Your subject has invalid fields.");
      }
      if (!(this.form.content && this.form.content.length > 20)) {
        return this.$message.warning(
          "Email body must be greater than 20 characters"
        );
      }
      const leftBracesCount = this.form.content.split("[[").length - 1;
      const rightBracesCount = this.form.content.split("]]").length - 1;
      if (leftBracesCount < rightBracesCount) {
        return this.$message.warning("Please remove unwanted ]]");
      } else if (leftBracesCount > rightBracesCount) {
        return this.$message.warning("Please remove unwanted [[");
      }
      const regex = /\[\[\s*]]/g;
      if (regex.test(this.form.content)) {
        return this.$message.warning("There should be field inside [[ ]]");
      }
      // Check for [[ before ]]
      if (/\[\[[^\]]*\[\[/.test(this.form.content)) {
        return this.$message.warning("No [[ allowed inside double brackets");
      }
      const leftParenthesesCount = this.form.content.split("((").length - 1;
      const rightParenthesesCount = this.form.content.split("))").length - 1;

      if (leftParenthesesCount < rightParenthesesCount) {
        return this.$message.warning("Please remove unwanted ))");
      } else if (leftParenthesesCount > rightParenthesesCount) {
        return this.$message.warning("Please remove unwanted ((");
      }

      const regexParentheses = /\(\(\s*\)\)/g;
      if (regexParentheses.test(this.form.content)) {
        return this.$message.warning("There should be content inside (( ))");
      }

      // Check for (( before ))
      if (/\(\((^\))*\(\(/.test(this.form.content)) {
        return this.$message.warning("No (( allowed inside double parentheses");
      }

      this.loadingText = "Creating Your Template";
      this.loading = true;
      if (!this.isEdit) {
        let params = {
          ...this.form,
          type: "ENTITY",
        };
          if  (this.mentionedRelationalTableFields?.length) {
            params["mentionedRelationalTableFields"] =
           
            this.mentionedRelationalTableFields;
            params["relationalTableData"] = this.relationalTableData;
            //clearing unwanted declarations of this format
            let regex = /\[\[DataTable-([^\]]+)\]\]/g;
            this.form.content = this.form.content.replace(
            regex,
            (match, fieldName) => {
              if (this.mentionedRelationalTableFields.includes(fieldName)) {
                return match;
              } else {
                return "";
              }
            }
          );
        }
        await this.$store.dispatch(
          "entityEmailTemplate/createEmailTemplate",
          params
        );
        if (this.getCreatedEmailTemplateStatus) {
          this.loading = false;
          this.$message.success("Template Created Successfully");
          this.$router.push({ path: "/email-template" });
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: this.getEmailTemplateError || "Error creating template",
          });
        }
      } else {
        let params = {
          ...this.form,
          company_id: this.currentTemplateData.company_id,
          created_at: this.currentTemplateData.created_at,
          created_by: this.currentTemplateData.created_by,
          updated_at: this.currentTemplateData.updated_at,
          updated_by: this.currentTemplateData.updated_by,
          _id: this.currentTemplateData._id,
        };
          if  (this.mentionedRelationalTableFields?.length) {
            params["mentionedRelationalTableFields"] =  this.mentionedRelationalTableFields;
            params["relationalTableData"] = this.relationalTableData;
            //clearing unwanted declarations of this format
            let regex = /\[\[DataTable-([^\]]+)\]\]/g;
            this.form.content = this.form.content.replace(
              regex,
              (match, fieldName) => {
                if (this.mentionedRelationalTableFields.includes(fieldName)) {
                  return match;
                } else {
                  return "";
                }
              }
            );
        }
        await this.$store.dispatch(
          "entityEmailTemplate/updateTemplateData",
          params
        );
        if (this.getUpdatedEmailTemplateStatus) {
          this.loading = false;
          this.$message.success("Template Updated Successfully");
          this.$router.push({ path: "/email-template" });
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: this.getEmailTemplateError || "Error Updating template",
          });
        }
      }
    },
    async handleBack() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: "/email-template",
        query,
      });
    },
    async fetchCurrentTemplateData(template_id) {
      let params = {
        template_id: template_id,
      };
      await this.$store.dispatch(
        "entityEmailTemplate/currentTemplateData",
        params
      );
      if (this.getCurrentEmailTemplateData) {
        this.currentTemplateData = this.getCurrentEmailTemplateData;
        this.form = {
          name: this.currentTemplateData.name,
          entity_id: this.currentTemplateData.entity_id,
          description: this.currentTemplateData.description,
          subject: this.currentTemplateData.subject,
          content: this.currentTemplateData.content,
        };
        // if (
        //   this.currentTemplateData?.form &&
        //   this.currentTemplateData?.form?.entity_id
        // ) {
        //   await this.getEntityFilters(this.currentTemplateData.form.entity_id);
        //   this.relationalDataForm = {
        //     ...this.currentTemplateData.form,
        //   };
        // }
        if (
          this.currentTemplateData?.mentionedRelationalTableFields?.length &&
          this.currentTemplateData?.relationalTableData?.length
        ) {
          this.mentionedRelationalTableFields = [
            ...this.currentTemplateData?.mentionedRelationalTableFields,
          ];
          this.relationalTableData = [
            ...this.currentTemplateData?.relationalTableData,
          ];
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getEmailTemplateError || "Error fetching template data",
        });
        this.$router.push("/email-template");
      }
    },
    async getEntityFilters(entity_id) {
      this.filterLoading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id,
      });
      this.entityAllFilters = [];
      this.relationalDataForm["filters"] = "";
      this.relationalDataForm["selectedFields"] = [];
      if (this.getAllEntityFilters) {
        this.entityAllFilters = this.getAllEntityFilters;
      }
      this.selectedEntityFields = await this.fetchEntityDetails(
        entity_id,
        true,
        true
      );
      this.selectedEntityFields = this.selectedEntityFields.filter(
        (e) =>
          e.properties?.filed_content != "Hide" && e.input_type != "DATA_TABLE"
      );
      this.filterLoading = false;
    },
    async fetchRelationalEntities(entity_id) {
      try {
        this.loading = true;
        let relationalEntityIds = [];
        await this.$store.dispatch(
          "entityRelationships/fetchEntityRelationships",
          { entity_id: entity_id, include_type: true }
        );
        if (this.getEntityRelationships) {
          (this.getEntityRelationships || []).map((e) => {
            // if (e.owner_type == "CHILD" || e.representation == "TAB") {
            relationalEntityIds = [
              ...relationalEntityIds,
              e.child_entity_id?._id,
              e.parent_entity_id?._id,
            ];
            // }
          });
          relationalEntityIds = Array.from(new Set(relationalEntityIds));
          if (relationalEntityIds && relationalEntityIds.length) {
            let indexToDelete = relationalEntityIds.indexOf(entity_id);
            if (indexToDelete !== -1) {
              relationalEntityIds.splice(indexToDelete, 1);
            }
          }
        }
        this.allEntitiesData = (this.getAllEntities.data || []).filter((e) => {
          if (relationalEntityIds.includes(e._id)) {
            return e;
          }
          return null;
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("fetchRelationalEntities", err);
      }
    },
    openTableConfig() {
      this.configureTableDialog = true;
    },
    ConfigureTableDialog() {
      this.configureTableDialog = false;
    },
    handleTableAdd() {
      if (!this.form.content.includes("[[relationalEntityDataTable]]")) {
        this.form.content =
          this.form.content + "<br> [[relationalEntityDataTable]]";
      }
      this.configureTableDialog = false;
    },
    copyURL(field) {
      navigator.clipboard.writeText(`[[${field}]]`);
      this.$message({
        message: "Field Copied!",
        type: "success",
      });
    },
    pushToSubject(field) {
      this.form.subject = this.form.subject + `[[${field}]]`;
      this.$message({
        message: `${field} added to body`,
        type: "success",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed-col {
  position: fixed;
  top: 132px;
  right: 30px;
  width: 523px;
}
.Email-Templates {
  display: flex;
}
.Select-in-Email-Templates {
  margin-left: 5px;
}

.try-using-ai {
  display: flex;
  align-items: center;
  margin-left: 350px;
  padding: 8px 18px;
  margin-bottom: 20px;
  border-radius: 35px;
  transition: all 0.3s ease;
  border-color: var(--primary-color) !important;
}
.try-using-ai:hover {
  color: #fff;
}
.try-using-ai img {
  margin-right: 10px;
}
.chatbot {
  /* display: -webkit-box; */
  /* display: flex; */
  /* -webkit-box-pack: center; */
  /* justify-content: center; */
}
.centered-col {
  margin-top: 5px;
}
.el-textarea__inner {
  min-height: 40px !important;
}
.responsive-col {
  margin-top: 20px;
  padding: 3px;
  position: sticky;
  top: 500;
}
@media (max-width: 1024px) and (min-width: 320px) {
  .Email-Templates {
    display: flex;
    flex-direction: column;
  }
  .chatbot-container {
    width: 100%;
  }
  .responsive-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .responsive-col {
    width: 100%;
  }
  .title {
    padding-left: 20px;
  }
  .Select-in-Email-Templates {
    margin-top: 20px;
    margin-left: 2px;
  }
}
@media (max-width: 320px) {
  .title {
    padding-left: 55px;
    font-size: 18px;
    margin-top: 0;
  }
  .Select-in-Email-Templates {
    margin-top: 20px;
    margin-left: 2px;
  }
}

.el-card {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 10px;
}
</style>
